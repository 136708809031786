import axiosInstance from '@/interceptors/axios.config';
import {useMemorialProfileStore} from "@/stores/memorial-profile";

const FormData = require('form-data');

export const uploadImage = (file, categoryName) => {
  const memorialProfileStore = useMemorialProfileStore();
  const apiUrl = process.env.VUE_APP_API_URL
  const slotNumber = memorialProfileStore.memorialProfile.slot_number;

  if (!slotNumber) {
    return;
  }

  const url = `${apiUrl}media/images/${slotNumber}`;
  const form = new FormData();

  form.append('image', file);
  form.append('category_name', categoryName);

  const config = {
    headers: {
      'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
    },
  };

  return axiosInstance.post(url, form, config)
    .then(response => response.data)
};

export const deleteImage = (photoUrl) => {
  const memorialProfileStore = useMemorialProfileStore();
  const slotNumber = memorialProfileStore.memorialProfile.slot_number;

  if (!slotNumber || !photoUrl) {
    return;
  }

  const apiUrl = process.env.VUE_APP_API_URL;
  const hash = extractHashFromUrl(photoUrl)
  const url = `${apiUrl}media/images/${slotNumber}/${hash}`;

  return axiosInstance.delete(url)
    .then(response => response.data)
};

const extractHashFromUrl = (url) => {
  if (!url) {
    return;
  }

  const parts = url.split('_');
  const hashWithExtension = parts[parts.length - 1];

  return hashWithExtension?.split('.')?.[0] ?? undefined;
};
